import { useMemo } from 'react';
import {
  FormControl,
  MenuItem,
  StandardTextFieldProps,
  TextField
} from '@mui/material';

export type SelectInputOption = {
  label: string;
  key: string | number;
};

export type SelectInputOptionBool = {
  label: string | boolean;
  key: string | number;
}

export interface SelectInputFieldProps extends StandardTextFieldProps {
  label: string;
  id: string;
  options: SelectInputOption[];
  headerOption?: SelectInputOption;
  comparator?: (
    firstOption: SelectInputOption,
    secondOption: SelectInputOption
  ) => number;
}

function getMenuItemFromSelectInputOption(
  selectInputOption: SelectInputOption,
  index: number
) {
  const { key, label } = selectInputOption;

  return (
    <MenuItem key={`${key}${index}`} value={key}>
      {label}
    </MenuItem>
  );
}

export const SelectInputField = (props: SelectInputFieldProps): JSX.Element => {
  const { options, label, comparator, headerOption, ...otherProps } = props;

  const optionsSorted: SelectInputOption[] = useMemo(
    () => (props.comparator ? [...options].sort(props.comparator) : options),
    [options, props.comparator]
  );

  return (
    <FormControl fullWidth>
      <TextField {...otherProps} select label={label}>
        {headerOption && getMenuItemFromSelectInputOption(headerOption, 0)}

        {optionsSorted.map((item, index) =>
          getMenuItemFromSelectInputOption(
            item,
            headerOption ? index + 1 : index
          )
        )}
      </TextField>
    </FormControl>
  );
};
