export enum PermissionId {
  DoSystemAdmin = 1, // aka SystemAdmin
  ReceiveModuleScheduleApprovalAlert = 4,
  CreatePlacementSchedule = 10,
  ViewPlacementSchedule = 11,
  EditPlacementSchedule = 12,
  DeletePlacementSchedule = 13,
  CreateThemeSchedule = 6,
  ViewThemeSchedule = 14,
  EditThemeSchedule = 15,
  DeleteThemeSchedule = 16,
  ViewClassList = 17,
  EditClassList = 18,
  DeleteClassList = 19,
  ViewStudentGroups = 20,
  EditStudentGroups = 21,
  DeleteStudentGroups = 22,
  CreateStudentGroups = 23,
  SchedulePlacement = 24,
  ViewSettings = 25,
  EditSettings = 26,
  DeleteSettings = 27,
  AddAccreditation = 28,
  EditAccreditation = 29,
  ViewAccreditation = 30,
  DeleteAccreditation = 31,
  AddForms = 32,
  EditForms = 33,
  ViewForms = 34,
  DeleteForms = 35,
  ViewUsers = 110,
  CreateUsers = 111,
  EditUsers = 112,
  DeleteUsers = 113,
  ScheduleUsers = 115,
  VerifyUsers = 116,
  ViewTags = 120,
  CreateTags = 121,
  EditTags = 122,
  DeleteTags = 123,
  ScheduleTags = 125,
  VerifyTags = 126,
  ViewActivityTypes = 130,
  CreateActivityTypes = 131,
  EditActivityTypes = 132,
  DeleteActivityTypes = 133,
  VerifyActivityTypes = 135,
  ViewThemeAutomation = 140,
  CreateThemeAutomation = 141,
  EditThemeAutomation = 142,
  DeleteThemeAutomation = 143,
  VerifyThemeAutomation = 146,
  ViewPlacementAutomation = 150,
  CreatePlacementAutomation = 151,
  EditPlacementAutomation = 152,
  DeletePlacementAutomation = 153,
  VerifyPlacementAutomation = 155,
  ViewEvents = 210,
  CreateEvents = 211,
  EditEvents = 212,
  DeleteEvents = 213,
  VerifyEvents = 216,
  ViewProgramme = 310,
  CreateProgramme = 311,
  EditProgramme = 312,
  DeleteProgramme = 313,
  VerifyProgramme = 316,
  ViewAllApplicationProgrammes = 317,
  EditAllApplicationProgrammes = 318,
  ViewAllModuleProgrammes = 319,
  ViewModule = 320,
  CreateModule = 321,
  EditModule = 322,
  DeleteModule = 323,
  ScheduleModule = 325,
  VerifyModule = 326,
  ViewAllApplicationModules = 327,
  EditAllApplicationModules = 328,
  ViewAllProgrammeModulesInAcademicYear = 329,
  ViewTheme = 330,
  CreateTheme = 331,
  EditTheme = 332,
  DeleteTheme = 333,
  VerifyTheme = 336,
  ViewApplicationThemes = 337,
  EditApplicationThemes = 338,
  ViewAllModuleThemes = 339,
  ViewActivity = 340,
  CreateActivity = 341,
  EditActivity = 342,
  DeleteActivity = 343,
  VerifyActivity = 346,
  ViewApplicationActivities = 347,
  EditApplicationActivities = 348,
  ViewAllThemeActivities = 349,
  ViewAllThemeModules = 350,
  ViewAllActivityThemes = 351,
  ViewAllProgrammeModules = 352,
  ViewFacility = 410,
  CreateFacility = 411,
  EditFacility = 412,
  DeleteFacility = 413,
  VerifyFacility = 416, //Not Used
  ViewTrainingArea = 420,
  CreateTrainingArea = 421,
  EditTrainingArea = 422,
  DeleteTrainingArea = 423,
  VerifyTrainingArea = 426, // Not Used
  ViewCapacity = 510,
  CreateCapacity = 511,
  EditCapacity = 512,
  DeleteCapacity = 513,
  DownloadCapacity = 514,
  VerifyCapacity = 516,
  ViewStudent = 610,
  CreateStudent = 611,
  EditStudent = 612,
  DeleteStudent = 613,
  DownloadStudentPlacements = 614,
  VerifyStudent = 616,
  ViewAcademicBooking = 710,
  CreateAcademicBooking = 711,
  EditAcademicBooking = 712,
  DeleteAcademicBooking = 713,
  VerifyAcademicBooking = 714,
  ApproveAllAcademicBookings = 715,
  ReceiveAcademicBookingAlert = 716,
  ViewAdhocBooking = 720,
  CreateAdhocBooking = 721,
  EditAdhocBooking = 722,
  DeleteAdhocBooking = 723,
  VerifyAdhocBooking = 724,
  ApproveAllAdhocBookings = 725,
  AcademicBookingAlert = 726,
  ViewAccommodationBooking = 730,
  CreateAccommodationBooking = 731,
  EditAccommodationBooking = 732,
  DeleteAccommodationBooking = 733,
  VerifyAccommodationBooking = 734,
  ApproveAllAccommodationBookings = 735,
  ReceiveAccommodationBookingAlert = 736,
  ViewTransportBooking = 740,
  CreateTransportBooking = 741,
  EditBooking = 712,
  EditTransportBooking = 742,
  DeleteTransportBooking = 743,
  VerifyTransportBooking = 744,
  ApproveAllTransportBookings = 745,
  ReceiveTransportBookingAlert = 746,
  DownloadBooking = 791,
  ViewSupervisor = 810,
  CreateSupervisor = 811,
  EditSupervisor = 812,
  DeleteSupervisor = 813,
  VerifySupervisor = 816,
  ViewStaff = 820,
  CreateStaff = 821,
  EditStaff = 822,
  DeleteStaff = 823, //Not Used
  VerifyStaff = 826, //Not Used
  ViewDocument = 910,
  CreateDocument = 911,
  EditDocument = 912,
  DeleteDocument = 913,
  VerifyDocument = 916,
  ViewMyModules = 1010,
  CreateMyModules = 1011,
  EditMyModules = 1012,
  DeleteMyModules = 1013,
  VerifyMyModules = 1016,
  ViewStudentSubGroups = 1018,
  CreateStudentSubGroups = 1019,
  EditStudentSubGroups = 1020,
  DeleteStudentSubGroups = 1021,
  BulkDeleteActivities = 1017,
  ViewFormCategories = 1026,
  EditFormCategories = 1027,
  DeleteFormCategories = 1028,
  CreateFormCategories = 1029,
  ViewFormTypes = 1022,
  EditFormTypes = 1023,
  DeleteFormTypes = 1024,
  CreateFormTypes = 1025,
  ViewFormQuestionValues = 1030,
  EditFormQuestionValues = 1031,
  DeleteFormQuestionValues= 1032,
  CreateFormQuestionValues = 1033
}
